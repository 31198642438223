










































































































































import {
    Vue, Component, Prop,
} from 'vue-property-decorator'
import {State, Getter} from 'vuex-class'
import api from '~/api'
import utils from '~/utils'
import ImgLazyLoad from '~/components/img-lazy-load.vue'
import ProductCover from '~/components/product-cover.vue'

@Component({
    components: {
        ImgLazyLoad,
        ProductCover,
    },
})
export default class CommonProductCard extends Vue {
    @Prop({required: true}) productInfo!: any

    @Prop({required: false, default: false}) showShop!: boolean

    @Prop({required: false, default: false}) isFavoriteProduct!: boolean

    @Prop({required: false, default: true}) showCreditTerm!: boolean

    @Prop({required: false, default: true}) showOeLabel!: boolean

    @Prop({required: false, default: false}) isIam!: boolean

    @Prop({required: false, default: ''}) sourcePage!: string

    @Prop({required: false, default: 200}) imgWidth!: number

    @Prop({required: false, default: 26}) coverTextHeight!: number

    @Prop({required: false, default: 'select_item'}) googleAnalyticEventName!: string

    @Prop({required: false, default: 'Search Results List Page'}) aDEntry!: string

    @State('token') token

    @State('countryId') countryId

    @State('countryCurrency') currency

    imgUrl: string = ''

    isCollect: boolean = true       // 默认收藏状态

    overLength: boolean = false // 长度超出50

    mounted() {
        this.imgUrl = this.$dealImgUrl(this.productInfo.thumb || this.productInfo.goodsImg, 300)
    }

    favoriteOperate() {
        if (this.isCollect) {
            api.my_favorite_cancel({ skuOrgId: this.productInfo.skuOrgId }).then(() => {
                this.isCollect = false
            })
        } else {
            api.my_favorite_collect({ skuOrgId: this.productInfo.skuOrgId }).then(() => {
                this.isCollect = true
                utils.fbq('track', 'AddToWishlist', {
                    content_name: this.productInfo.goodsName || this.productInfo.title,
                    content_category: this.productInfo.thirdCategoryName,
                    content_ids: this.productInfo.skuOrgId,
                    contents: [{
                        id: this.productInfo.skuOrgId,
                        quantity: 1,
                    }],
                    currency: this.$store.state.currentCountry.currency,
                    value: this.productInfo.standardPrice,
                })
            })
        }
    }

    i18nReplace(s, o) {
        return utils.i18nReplace(s, o)
    }

    gotoProductDetail() {
        if(this.productInfo.advertisementId){
            let customizeValue = {
                "skuOrgId": this.productInfo.skuOrgId,
                "advertisementPlacement": this.aDEntry,
                "advertisementId": this.productInfo.advertisementId
            }            
        this.batchSave(customizeValue,'Click_Ads_SKU')
        }
        this.$emit('triggerGotoProductDetail')
        switch (this.googleAnalyticEventName) {
            case 'click_product_in_you_may_like_homepage':
                this.dealGoogleAnalyticYouMayLike(this.googleAnalyticEventName)
                break
            case 'click_product_in_you_may_also_like':
                this.dealGoogleAnalyticYouMayLike(this.googleAnalyticEventName)
                break
            case 'click_you_may_like_in_product_detail':
                this.dealGoogleAnalyticYouMayLike(this.googleAnalyticEventName)
                break
            case 'select_item':
                this.dealGoogleAnalyticNormal()
                break
            default:
                this.dealGoogleAnalyticNormal()
        }

        let { oeNumber, vehicleModelId } = this.productInfo
        let link = this.$router.resolve(this.localePath({
            name: 'productDetailPage',
            params: {
                id: this.productInfo.skuOrgId,
            },
            query: {
                oeNumber,
                vehicleModelId,
                sourcePage: this.sourcePage,
                advertisementId: this.productInfo.advertisementId || null,
                advertisementPlacement: this.productInfo.advertisementPlacement || null
            },
        }))
        window.open(link.href, '_blank') // 打开新窗口
    }

    gotoShopDetail() {
        this.$router.push(this.localePath({
            name: 'shopDetailPage',
            params: {
                id: this.productInfo.shopId,
            },
        }))
    }

    dealGoogleAnalyticYouMayLike(eventName) {
        utils.ga4('event', eventName, {
            item_id: this.productInfo.skuOrgId,
            item_name: this.productInfo.goodsName || this.productInfo.title,
            currency: this.$store.state.currentCountry.currency,
            item_brand: this.productInfo.brandName,
            price: this.productInfo.standardPrice,
            algorithm_rules_source: this.productInfo.youMayAlsoLikeSource,
        })
    }

    dealGoogleAnalyticNormal() {
        utils.ga4('event', 'select_item', {
            shopId: this.productInfo.shopId,
            items: [{
                item_id: this.productInfo.skuOrgId,
                item_name: this.productInfo.goodsName || this.productInfo.title,
                currency: this.$store.state.currentCountry.currency,
                item_brand: this.productInfo.brandName,
                item_category: this.productInfo.thirdCategoryNameBuried,
                price: this.productInfo.standardPrice,
                item_category2: this.productInfo.shopId,
            }],
        })
    }

    get getBrandSku() {
        const str: string = this.productInfo.brandSku || ''
        if (str.length >= 50) {
            this.overLength = true
            return str.slice(0, 50)
        }
        return str
    }

    batchSave(customizeValue, elementId) {
        let params = {
            datas: [{
                appId: 'EGG_MALL_WEB',
                appVersion: 'EGG_MALL_WEB',
                carrier: 'EGG_MALL_WEB',
                customizeValue,
                deviceId: 'EGG_MALL_WEB',
                dynamicResourceType: 1,
                elementId,
                eventTime: 1,
                eventType: 1,
                ip: 'EGG_MALL_WEB',
                isFirstTime: 1,
                latitude: 'EGG_MALL_WEB',
                libVersion: 'EGG_MALL_WEB',
                longitude: 'EGG_MALL_WEB',
                manufacturer: 'EGG_MALL_WEB',
                model: 'EGG_MALL_WEB',
                networkType: 'EGG_MALL_WEB',
                operatingSystem: 'WEB',
                operatingSystemVersion: 'EGG_MALL_WEB',
                referPage: 'EGG_MALL_WEB',
                screenHeight: 'EGG_MALL_WEB',
                screenName: 'EGG_MALL_WEB',
                screenOrientation: 1,
                screenWidth: 'EGG_MALL_WEB',
                sessionId: 'EGG_MALL_WEB',
                timeZone: 'EGG_MALL_WEB',
                userId: this.$store.state.userId || 0,
                businessPlatform: 11,
            }],
        }
        api.event_tracking(JSON.stringify(params))
    }

    openPromotionPage(promotion) {
      if (promotion.productRange === 1) {
        let len = Math.floor(Math.random() * (promotion.shopIdList || []).length)
        let link = this.$router.resolve(this.localePath({
          name: 'multiConditionSearchPage',
          query: {
            shopIdList: promotion.shopIdList || [],
          },
        }))
        window.open(link.href, '_blank')
       } else return utils.openBannerPromotionPage(this, promotion.activityUrl)
    }
}
