import { render, staticRenderFns } from "./common-product-card.vue?vue&type=template&id=06a5d1ad&scoped=true&"
import script from "./common-product-card.vue?vue&type=script&lang=ts&"
export * from "./common-product-card.vue?vue&type=script&lang=ts&"
import style0 from "./common-product-card.vue?vue&type=style&index=0&id=06a5d1ad&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "06a5d1ad",
  null
  
)

export default component.exports